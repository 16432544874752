<template lang="pug">
div.pb-24
	template(v-if="isVisibleData")
		Skeleton(v-if="isLoading")

		template(v-else)
			h2.mb-24.tab-title Занятие топ-10 в категории, %

			ul.flex.card-list(v-if="res")
				li.card-item(v-if="res.stores")
					ContainerForData.card(width="100%")
						template(#header-left)
							span.card-title Online Stores
						template(#data)
							.content
								table.table
									tbody
										tr(v-for="store in res.stores" :key="store.storeId")
											td {{ store.store.name }}
											td
												.py-12.flex.items-center
													ProgressBarGraphic.mr-12(:progress-percent="store.averagePercentage")
													| {{ store.averagePercentage }}%

				li.card-item(v-if="res.category")
					ContainerForData.card(width="100%")
						template(#header-left)
							span.card-title Categories
						template(#data)
							.content
								table.table
									tbody
										tr(v-for="category in res.category" :key="category.filterId")
											td {{ category.filter.name }}
											td
												.py-12.flex.items-center
													ProgressBarGraphic.mr-12(:progress-percent="category.averagePercentage")
													| {{ category.averagePercentage }}%

				li.card-item(v-if="res.brands")
					ContainerForData.card(width="100%")
						template(#header-left)
							span.card-title Brands
						template(#data)
							.content
								table.table
									tbody
										tr(v-for="brand in res.brands" :key="brand.brandId")
											td {{ brand.brand.name }}
											td
												.py-12.flex.items-center
													ProgressBarGraphic.mr-12(:progress-percent="brand.averagePercentage")
													| {{ brand.averagePercentage }}%
													
				li.card-item(v-if="res.subcategory")
					ContainerForData.card(width="100%")
						template(#header-left)
							span.card-title Subcategory
						template(#data)
							.content
								table.table
									tbody
										tr(v-for="category in res.subcategory" :key="category.categoryId")
											td {{ category.category.name }}
											td
												.py-12.flex.items-center
													ProgressBarGraphic.mr-12(:progress-percent="category.averagePercentage")
													| {{ category.averagePercentage }}%

	.empty-filter(v-else) Пожалуйста, выберите значения фильтра
</template>

<script>
import Skeleton from '@/components/Nestle/Skeleton/Common.vue';
import ContainerForData from "@/components/Nestle/ContainerForData";
import ProgressBarGraphic from '@/components/Chart/ProgressBarGraphic.vue';
import { renameYandexByName } from '@/helpers/RenameYandexPokupki';

export default {
	name: "ShareOfTaxonomy",
	components: {
		Skeleton,
		ContainerForData,
		ProgressBarGraphic,
	},
	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		date: {
			type: Object,
			default: () => {},
		},
		isVisibleData: {
			type: Boolean,
			default: false,
		},

		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			res: null,
			current: 1,
			limit: 20,
			offset: 0,
			isLoading: true,
		}
	},
	methods: {
		async fetch() {
			this.$emit('showData')
			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
				category: this.group,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				offset: this.offset,
				limit: this.limit,
			};
			try {
				this.isLoading = true

				const result = await this.$api.nestle.getShareOfTaxonomy(params)
				const renamedItems = renameYandexByName(result)
				this.res = renamedItems
			} catch {
				this.res = null;
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		needUpdateResult: {
			immediate: true,
			async handler() {
				this.offset = 0
				this.current = 1
				await this.fetch()
			}
		},
		current: {
			handler() {
				this.offset = this.limit * (this.current-1)
				this.fetch()
			},
		},
	},

}
</script>

<style lang="scss" scoped>
.tab-title {
	font-family: 'OpenSans';
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	color: #000000;
}

.card-list {
	gap: 24px;
}

.card-item {
	flex: 1 1 302px;
}

.card {
	min-height: 100%;
}

.card-title {
	font-family: 'OpenSans';
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	color: #757575;
}

.content {
	width: 100%;
	min-height: 400px;
}

.table {
	width: 100%;
}

td {
	font-family: 'OpenSans';
	font-size: 10px;
	font-weight: 400;
	line-height: 16px;
	color: #A2AEBE;

	&:first-of-type {
		padding-right: 12px;
	}

	&:last-of-type {
		width: 132px;
		border-bottom: 1px solid #F5F5F5;
	}
}

tr:first-of-type td:last-of-type {
	border-top: 1px solid #F5F5F5;
}

.empty-filter {
	font-size: 16px;
	display: flex;
	justify-content: center;
	margin-top: 30px;
}
</style>	
