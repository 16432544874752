import { render, staticRenderFns } from "./ShareOfTaxonomy.vue?vue&type=template&id=3315041e&scoped=true&lang=pug&"
import script from "./ShareOfTaxonomy.vue?vue&type=script&lang=js&"
export * from "./ShareOfTaxonomy.vue?vue&type=script&lang=js&"
import style0 from "./ShareOfTaxonomy.vue?vue&type=style&index=0&id=3315041e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3315041e",
  null
  
)

export default component.exports